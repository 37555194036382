<template>
    <div class="min-h-screen flex flex-col">
        <MenuApp></MenuApp>

        <Home-pop-up></Home-pop-up>

        <div class="mb-auto grow w-full flex">
            <slot></slot>
        </div>

        <ChatFloating :slightly-up="isMobile"></ChatFloating>
    </div>
</template>

<script>
import HomePopUp from "~/pages/components/HomePopUp.vue";

export default {
    name: "DefaultLayoutChatUpMobileWithoutFooter",
    components: {HomePopUp},
    computed: {
        isMobile() {
            return useNuxtApp().$isMobile?.value;
        }
    }
}
</script>

<style scoped lang="scss">

</style>
